.container{
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.icon-picker{
   position: relative;
}
.icon-picker-container{
   position: absolute;
   top: calc(100% + 5px);
   width:  100%;
   height: 250px;
   z-index: 100000;
   background-color: #fff;
   border-radius: 3px;
   box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
   padding: 5px 20px;
   box-sizing: border-box;

}

.icon-container{
   height: 150px;
   width: calc(100% + 20px);
   overflow-y:scroll ;
   overflow-x: hidden;
   display: flex;
   flex-wrap: wrap;
   .icon-item-container{
      width: 25%;
      display: flex;
      overflow: hidden;
      padding-right: 20px;
      box-sizing: border-box;
      .key{
         margin-left: 10px;
         margin-right: 10px;
      }
      &:hover{
         background-color: #dcdcdc;
         cursor: pointer;
      }
   }
}