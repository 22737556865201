.container{
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.login{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  .login-left{
    flex: 7;
    background-image: url("../../assets/image/login-bg-1.svg");
    background-repeat: no-repeat;
    background-size: 70% 70%;
    background-position: center;
    height: 100%;
    background-color: #FAFAFA;
  }
  .login-right{
    flex: 3;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    .login-anther-login{
      margin: 30px auto;
    }
    .login-form{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      .login-btn{
        width:auto ;
      }
    }
  }

}
