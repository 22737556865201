.app{
  width: 100vw;
  display: flex;

}
.app-left{
  width: fit-content;
  min-height: 100vh;
  background-color: rgb(0, 21, 41);
  transition: width .5s !important;
}
.app-right{
  flex: 1;
  display: flex;
  flex-direction: column;
  transition: width .5s !important;

}
.app-right-top{
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  /*border-bottom: 1px solid #292934;*/
  box-shadow: 1px 1px 2px 2px #e5e5e5;
}
.app-right-top .menu{
   height: 100%;
   border: none;
   width: 40px;
   display: flex;
   align-items: center;
   justify-content: center;
}
.app-right-center{
  flex: 1;
}
.app-right-bottom{
  background-color: #292934;
  width: 100%;
  height: 50px;
}

.demo-logo-vertical{
    height: 40px;
    width: calc(100% - 40px);
    background-color: #334454;
    margin: 15px auto;
    border-radius: 5px;

}